import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import  { Redirect } from 'react-router-dom'
import Modal from 'react-modal';
import $ from 'jquery';
import queryString from 'query-string';
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './App.css';

import svg_height from './assets/images/svg/height-icon.svg';
import svg_weight from './assets/images/svg/weight-icon.svg';
import svg_eye from './assets/images/svg/eye-icon.svg';
import svg_hair from './assets/images/svg/hair-icon.svg';
//import svg_lang from './assets/images/svg/characteristics.svg';
import svg_lang from './assets/images/chracteristics.png';
import svg_donor from './assets/images/svg/donor-icon.svg';
//import svg_cycle from './assets/images/svg/share-cycles-icon.svg';
import svg_cycle from './assets/images/shared-cycle_small.png';
import svg_frozen from './assets/images/svg/frozen-icon.svg';

import svg_photos from './assets/images/svg/photos-icon.svg';
import svg_about from './assets/images/svg/about-icon.svg';
import svg_education from './assets/images/svg/education-icon.svg';
import svg_health from './assets/images/svg/health-icon.svg';
import svg_donation from './assets/images/svg/donations-icon.svg';
import svg_info from './assets/images/svg/info-icon.svg';
import img_placeholder from './assets/images/donor_placeholder.jpg';

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
};

//Modal.setAppElement('.content')

class Donor extends Component {

    constructor(props) {
        super (props);
        this.state = {
            load_data: 0,
            bookmark: this.props.bookmark,
            modalIsOpen: false
        }
        this.p_info = '';
        this.p_id = this.props.did;
        this.p_rid = this.props.rid;

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.updateBookmark = this.updateBookmark.bind(this);
        //this.bookmark = this.props.bookmark;

        this.api_message = '';
        this.popup_html = '';
        this.cta_html = '';
    }

    componentWillMount() {


    }

 
    componentDidMount() {
        //document.body.addEventListener('contextmenu', this.onImgMouseDown);
    }

    onImgMouseDown(e) {
        console.log('Mouse Donwload:', e.button);
        e.preventDefault();
        if(e.button == 2) { // right click
            
            console.log('Mouse Donwload Do nothing');
            return false; // do nothing!
        }
        return false;
    }    
    
    onClickDonor(e) {
        e.preventDefault(); 
        
        console.log('Click Donor value:' + e.target.value);
        console.log('Click Donor className:' + e.target.className);
        var className = e.target.className;
        if (className != '' && 
            className != 'donor-pane' && 
            className != 'donor-info-main' && 
            className.search('donor-info-row') < 0 && 
            className != 'donor-user-subheading' && 
            className != 'donor-user-heading' && 
            className != 'donor-heading-value' &&             
            className.search('donor-img') < 0) {
            console.log('Donor Click Ignored:' + className);
            return;
        }

        var donor_id = this.p_id;
        var url = '/profile?id=' + donor_id;
        console.log('Click Donor: ' + url);
        window.location = url;
    }

    updateBookmark(e) {
        console.log('Click Bookmark Icon');

        const {bookmark} = this.state;
        this.setState({modalIsOpen: false});
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(1);
        }

        var url;
        console.log('Click Bookmark:' + bookmark);
        if (bookmark == true)
            url = global.api_url + 'recipient/release_bookmark/' + this.p_rid;
        else 
            url = global.api_url + 'recipient/set_bookmark/' + this.p_rid;

        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json', 
            data: {token:global.token , donor_id: this.p_id},
            cache: false,
            crossDomain: true,
            success: function(response) {
                
                if (response.success == true) {
                    var new_val = !bookmark;
                    console.log('Update Bookmark:' + new_val);

                    //this.setState({bookmark:new_val});       
                    if (typeof this.props.onChange === 'function') {
                        this.props.onChange(2);
                    }
                } else {
                    this.api_message = response.message;
                    //this.setState({load_data: -1});                    
                    console.log('Ajax API failed:', this.api_message);
                }
            }.bind(this)
        });    

    }

    onClickBookmark(e) {
        e.preventDefault();       
        
        const {bookmark} = this.state;
        
        if (bookmark == false) {
            console.log('Unmarked Donor');
            //return;
            this.popup_html = 'Are you sure you want to add this donor to your bookmarks?';
            this.cta_html = 'ADD BOOKMARK';
            
        } else {
            this.popup_html = 'Are you sure you want to remove this donor from your bookmarks?';
            this.cta_html = 'REMOVE BOOKMARK';
        }
        
        this.setState({modalIsOpen: true});
        return;
     
    }

    openModal() {
        this.setState({modalIsOpen: true});
    }
    
    afterOpenModal() {
    // references are now sync'd and can be accessed.
        //this.subtitle.style.color = '#f00';
    }
    
    closeModal() {
        this.setState({modalIsOpen: false});
    }

    renderModal(){
        return (
            <Modal
                isOpen={this.state.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="Example Modal" 
                ariaHideApp={false}
            >
                <div className="modal_box">
                    <i className="fa fa-times" aria-hidden="true" onClick={this.closeModal}></i>
                    
                    <h2 className="modal_title">{this.popup_html}</h2>
                    <div className="modal_btns">
                        <button className="btn btn-white btn-prompt-cancel" onClick={this.closeModal}>CANCEL</button>
                        <button className="btn btn-green btn-prompt-remove" onClick={this.updateBookmark}>{this.cta_html}</button>    
                                    
                    </div>
                </div>
          </Modal>            
        ) ;
    }

    render() {
    
        var items = [];    
        var bookmarks = [];
        var first_elem = true;
        if (this.props.donatedbefore == 'yes') {
            items.push(<img className="extra-img small" src={svg_donor} /> );
            items.push(<div className="donor-heading-value1 pl-2"> <span>Previous Donor</span> </div>);
            first_elem = false;
        }       

        if (this.props.show_home > 0) {
            if (first_elem == false)
                items.push(<img className="extra-img ml-2" src={svg_cycle} /> );
            else 
                items.push(<img className="extra-img" src={svg_cycle} /> );
            
            items.push(<div className="donor-heading-value1 pl-2"> <span>Shared Cycles</span> </div>);
            first_elem = false;            
        }

        if (this.props.freezeeggs >0 ) {            
            if (first_elem == false)
                items.push(<img className="extra-img ml-2" src={svg_frozen}></img> );
            else 
                items.push(<img className="extra-img" src={svg_frozen}></img> );
            items.push(<div className="donor-heading-value1 pl-2"> <span>Frozen Eggs</span> </div>);
            first_elem = false;
        } 

        var bookmark = this.props.bookmark;
        //const {bookmark} = this.state;
        if (bookmark == true ) {
            bookmarks.push(<i className="fa fa-heart" aria-hidden="true"></i>);
        }  else {
            bookmarks.push(<i className="fa fa-heart-o" aria-hidden="true"></i>);
        }

        var city = this.props.city.trim();
        var state = this.props.state.trim();

        return (
        <div className="donor-pane" onClick={this.onClickDonor.bind(this)}>
            <a className="icon-fav" onClick={this.onClickBookmark.bind(this)}>
                {bookmarks}
            </a>

            <div className="donor-info-row text-left">
                <h1 className="donor-user-heading">{this.props.name}  (ID {this.props.did})</h1>
            </div>
            <div className="donor-info-row text-left">
                <div className="donor-user-subheading">{city},&nbsp;{state}&nbsp;·&nbsp;{this.props.age} years old</div>                        
            </div>
            <div className="donor-info-main">
                <LazyLoadImage 
                    key={this.p_id} 
                    wrapperClassName="donor-img-box" 
                    className="donor-img" 
                    effect="black-and-white" 
                    alt="Featured Photo" 
                    src={this.props.photo} />

                <div className="donor-info-content" >
                    {
                        this.props.height?
                        <div className="donor-info-row flex-row flex-align-center text-left">
                            <img className="donor-info-icon" src={svg_height}></img> 
                            <div className="donor-heading-value"> <span>{this.props.height}</span> </div>
                        </div>
                        :""
                    }

                    <div className="donor-info-row flex-row flex-align-center text-left">
                        <img className="donor-info-icon" src={svg_weight}></img> 
                        <div className="donor-heading-value"> 
                            <span>
                                {this.props.weight}&nbsp;lbs
                                {this.props.bodytype?" / ":""}
                                {this.props.bodytype}
                            </span>                         
                        </div>                                        
                    </div>

                    {
                        this.props.eyecolor?
                        <div className="donor-info-row flex-row flex-align-center text-left">
                            <img className="donor-info-icon" src={svg_eye}></img>
                            <div className="donor-heading-value"> <span>{this.props.eyecolor}</span> </div>                                        
                        </div>
                        :""
                    }

                    <div className="donor-info-row flex-row flex-align-center text-left">
                        <img className="donor-info-icon" src={svg_hair}></img> 
                        <div className="donor-heading-value"> 
                            <span>
                                {this.props.haircolor}
                                {this.props.hairtype?" / ":""}
                                {this.props.hairtype}                            
                            </span> 
                        </div>                                        
                    </div>

                    {
                        this.props.race?
                        <div className="donor-info-row flex-row flex-align-center text-left">
                            <img className="donor-info-icon" src={svg_lang}></img>
                            <div className="donor-heading-value"> {this.props.race}</div>                                        
                        </div>  
                        :""
                    }
  
                </div>              
            </div>
        
            <div className="donor-info-row extra-info flex-row flex-align-center text-left mt-3">
                {items}
            </div>


            {this.renderModal()}            
        </div>
        );
    }
}

export default Donor;