import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css'
import React, { Component } from 'react';

import $ from 'jquery';
import HttpsRedirect from 'react-https-redirect';
import './App.css';

import TLogin from './tlogin';
import TLanding from './tlanding'
import TProfile from './tprofile';
import TAccount from './taccount';
import TSearch from './tsearch';
import TForgot from './tforgot';
import TPassword from './tpassword';

import TRecipient_Register from './register/recipient';
import TDonor_Register from './register/donor';
import TDonor_Application from './register/application';
import Test from './test';

import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'
import TVerify from './register/verify';



class App extends Component {
  constructor(){
      super();
      
      //global.api_url = "https://members.eggdonor.com/api/v2/";     
      global.api_url = process.env.REACT_APP_API_URL;
	  global.image_url = process.env.REACT_APP_IMAGE_URL;
      global.title = 'Member Login';
      global.token = '';
      global.r_id = 0;
      global.type = 'recipient';
  }

  render() {
    return (
      
        <Router>
          <div>
            <Switch>
              <Route exact={true} path='/test' Component={Test} render={(props) => (
                <div className="App">
                  <Test />                
                </div>
              )}/>

              <Route exact={true} path='/' Component={TLanding} render={(props) => (
                <div className="App">
                  <HttpsRedirect>
                    <TLanding />  
                  </HttpsRedirect>                                
                </div>
              )}/>

              <Route exact={true} path='/login' Component={TLogin} render={(props) => (
                <div className="App">
                  <HttpsRedirect>
                    <TLogin />                
                  </HttpsRedirect>                  
                </div>
              )}/>

              <Route exact={true} path='/change-password' Component={TPassword} render={(props) => (
                <div className="App">
                  <HttpsRedirect>
                    <TPassword />                  
                  </HttpsRedirect>                  
                </div>
              )}/>

              <Route exact={true} path='/forgot-password' Component={TForgot} render={(props) => (
                <div className="App">
                  <HttpsRedirect>
                    <TForgot />      
                  </HttpsRedirect>                              
                </div>
              )}/>

              <Route exact={true} path='/account' Component={TAccount} render={(props) => (
                <div className="App">
                  <HttpsRedirect>
                    <TAccount props={props}/>     
                  </HttpsRedirect>                               
                </div>
              )}/>

              <Route exact={true} path='/profile' Component={TProfile} render={(props) => (
                <div className="App">
                  <HttpsRedirect>
                    <TProfile props={props} />  
                  </HttpsRedirect>                  
                </div>
              )}/>

              <Route exact={true} path='/search' Component={TSearch} render={(props) => (
                <div className="App">
                  <HttpsRedirect>
                    <TSearch props={props} />  
                  </HttpsRedirect>
                  
                </div>
              )}/>      

              <Route exact={true} path='/recipient-registration' Component={TRecipient_Register} render={(props) => (
                <div className="App">
                  <HttpsRedirect>
                    <TRecipient_Register props={props} />  
                  </HttpsRedirect>
                  
                </div>
              )}/>     

              <Route exact={true} path='/donor-registration' Component={TDonor_Register} render={(props) => (
                <div className="App">
                  <HttpsRedirect>
                    <TDonor_Register props={props} />                    
                  </HttpsRedirect>                  
                </div>
              )}/>  

              <Route exact={true} path='/donor-application' Component={TDonor_Application} render={(props) => (
                <div className="App">
                  <HttpsRedirect>
                    <TDonor_Application props={props} />  
                  </HttpsRedirect>                  
                </div>
              )}/>              

              <Route exact={true} path='/verify' Component={TVerify} render={(props) => (
                <div className="App">
                  <TVerify>
                    <TVerify props={props} />                    
                  </TVerify>                  
                </div>
              )}/>  

            </Switch>
          </div>
        </Router>
    );
  }
}

export default App;
$.ajaxSetup({
  headers: { 'X-API-KEY': process.env.REACT_APP_API_KEY}
});