import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import  { Redirect } from 'react-router-dom'
import $ from 'jquery';
import queryString from 'query-string';
import {isMobile} from 'react-device-detect';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';

import './App.css';

import img_logo from './assets/images/logo.png';

class Header extends Component {

    constructor(props) {
        super (props);
        this.state = {
            loggined: 0,
            isOpen: false
        }

        this.toggle = this.toggle.bind(this);
    }

    componentWillMount() {
        this.setState({loggined: this.is_loggined()});
    }

 
    componentDidMount() {
        
    }

    is_loggined() {
        var token = window.localStorage.getItem('token');
        if(!token || token === '' || token === "undefined"){
            return false;
        }
        return true;
    }

    logOut(e) {
        e.preventDefault();

        var url = global.api_url + 'login/logout';
        
        window.localStorage.clear();
        
        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json', 
            cache: false,
            crossDomain: true,
            success: function(rsp) {
                console.log('Ajax Success');
                window.location = '/login';
            }.bind(this)
        });         
    }

    toggle() {
        this.setState({
          isOpen: !this.state.isOpen
        });
    }

    render_menu () {
        var name = global.title;
        var type = global.type;
        if (isMobile) {
            return (
                <Nav className="ml-auto" navbar>
                    {
                         type == 'recipient'?
                         <NavItem>
                            <NavLink href="/account">Account</NavLink>
                        </NavItem>:""
                    }

                    {
                         type == 'recipient'?
                         <NavItem>
                            <NavLink href="/search">Search</NavLink>
                        </NavItem>:""
                    }

                    <NavItem onClick={this.logOut.bind(this)}>
                        <NavLink href="#" >Logout</NavLink>
                    </NavItem>
                </Nav> 
            );
        }

        return (
            <Nav className="ml-auto" navbar>
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav>
                        <i className="fa fa-user-o" aria-hidden="true"></i>             
                        <span className="nav-text">&nbsp;&nbsp;{name}</span>      
                    </DropdownToggle>
                    {
                        type == 'recipient'?
                        <DropdownMenu right>
                            <NavLink href="/account">Account</NavLink>
                            <NavLink href="/search">Search</NavLink>
                            <DropdownItem divider />
                            <DropdownItem onClick={this.logOut.bind(this)}>
                            Logout
                            </DropdownItem>
                        </DropdownMenu>  
                        :
                        <DropdownMenu right>
                            <DropdownItem onClick={this.logOut.bind(this)}>
                            Logout
                            </DropdownItem>
                        </DropdownMenu>                                                     
                    }
                </UncontrolledDropdown>   
            </Nav>         
        );        
    }

    render() {
        const {loggined} = this.state;
        var type = global.type;
        
        return (

            <Navbar color="light" light expand="md">
                <div className="container">
                    <NavbarBrand href="/"><img className="logo-img" src={img_logo} alt=""></img></NavbarBrand>
                    {loggined?<NavbarToggler onClick={this.toggle} />:""}
                    
                    {
                        loggined?
                        <Collapse isOpen={this.state.isOpen} navbar>                        
                            {this.render_menu()}                        
                        </Collapse>
                        :""
                    }
                </div>
            </Navbar>
           
        );
    }
}

export default Header;
