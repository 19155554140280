
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import  { Redirect } from 'react-router-dom'

import $ from 'jquery';
import queryString from 'query-string';
import Header from './header';
import {isMobile} from 'react-device-detect';
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import ReactTooltip from 'react-tooltip';

import '../App.css';

class TVerify extends Component {
    constructor(props) {
        super(props);
        this.state = {
            load_data: false
        }

        this.type = '';
        this.email = '';
        this.verify = '';
        this.message = '';
    }

    componentWillMount() {
        var params = queryString.parse(window.location.search);
        this.type = params.type;   
        this.email = params.email;
        this.verify = params.verify;

        this.verifyemail();

    }
 
    componentDidMount() {

    }

    verifyemail() {
        var url;
        if (this.type == 'donor') {
            url = global.api_url + 'donor/verify';
        } else {
            url = global.api_url + 'recipient/verify';
        }
        
        
        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json', 
            data: {email: this.email, verify: this.verify},
            cache: false,
            crossDomain: true,
            success: function(response) {
                this.message = response.message;
                this.setState({load_data: true});
            }.bind(this)
        });       
    }

    render_header() {
        return (
            <div className="header-box">
                <h1 className="s-heading">Parent Registration</h1>
                <div className="s-title1">Thank you for your interest in our elite database of egg donors!</div>

                <p className="s-label1">
                Through our careful selection of prescreened, intellegent, healthy beautiful, and age appropriate egg donors from around the United States, we have helped more than 14,000 recipient families since 1989. We invite you to register to view the world's largest database of elite egg donor profiles - all without charge!
                </p>
            </div>            
        );
    }



    render() {
        const {load_data, verified} = this.state;

        if (load_data == false) {
            return (
                <div>
                    <Header></Header>  
                    <div id="loading"></div>                  
                </div>
            );
        }

        return (
            <div>
                <Header></Header>
                <div className="content-overlay">
                    <div className="login-overlay">
                        <div className="widget-message">{this.message}</div>   
                        <a className="u-link pt-5" href="/login" >Go to login</a>                                 
                    </div>               
                </div>   
            </div>
        );
    }
}

export default TVerify;