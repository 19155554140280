import React, { Component } from 'react';

import $ from 'jquery';
import queryString from 'query-string';
import Header from './header';
import logo from './logo.svg';

import img_logo from './assets/images/logo.png';
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import * as F_UTIL from './helper';
import './App.css';


class TPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            status: false
        }

        this.addNotification = this.addNotification.bind(this);
        this.notificationDOMRef = React.createRef();
    }

    componentWillMount() {
        //window.localStorage.clear();
        document.title = 'Forgot Password';
    }

    componentDidMount() {

    }

    addNotification(type, msg) {
        this.notificationDOMRef.current.addNotification({
          title: "",
          message: msg,
          type: type,
          insert: "top",
          container: "bottom-center",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: { duration: 4000 },
          dismissable: { click: true }
        });
    }

    forgotPassword(e) {
        e.preventDefault();
        const form = e.target;
        const data = new FormData(form);

        var email =  data.get('email');
        var domain = window.location.protocol + "//" + window.location.host;

        if (email == '') {
            this.addNotification('danger', 'No Email field');
            return;
        }

        var url = global.api_url + 'login/forgot';        
        //window.localStorage.clear();
        
        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json', 
            data: {email: email, domain: domain},
            cache: false,
            crossDomain: true,
            success: function(rsp) {
                //this.addNotification('success', 'Your request has been sent');
                console.log('API rsp:' + rsp.message);
                if (rsp.success == true) {                    
                    this.setState({status: true, message: rsp.message});
                } else {                    
                    this.setState({status: false, message: rsp.message});                    
                }
            }.bind(this)
        }); 
    }

    render() {
        console.log('Content render');
        const {message} = this.state;

        if (message) {
            return (
                <div>
                    <Header></Header>
                    <ReactNotification ref={this.notificationDOMRef} />
                    <div className="content-overlay">
                        <div className="login-overlay">
                            <div className="widget-message">{message}</div>      
                            <a className="u-link pt-5" href="/login" >Go to login</a>                                              
                        </div>               
                    </div>
    
                </div>
            );
        } else {
            return (
                <div>
                    <Header></Header>
                    <ReactNotification ref={this.notificationDOMRef} />
                    <div className="content-overlay">
                        <div className="login-overlay">
                            <div className="login-widget">
                                <div className="login-box">
                                    <h1 className="title">RESET PASSWORD</h1>
                                    <form className="form-login" onSubmit={this.forgotPassword.bind(this)}>
                                        <div className="form-group">
                                            <input type="email" className="form-control" name="email" id="email" placeholder="email" defaultValue=""></input>                                            
                                        </div>
                                        <div className="form-group">
                                            <input type="submit" value="Verify Email" className="btn btn-login"></input>
                                        </div>  
                                    </form>
                                </div>
                            </div>            
                        </div>               
                    </div>
                </div>
            );
        }

    }
}

export default TPassword;