import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import  { Redirect } from 'react-router-dom'

import $ from 'jquery';
import queryString from 'query-string';
import Header from './header';
import StackGrid from "react-stack-grid";
import {isMobile} from 'react-device-detect';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import Modal from 'react-modal';
import * as F_UTIL from './helper';
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import ReactTooltip from 'react-tooltip';

import './App.css';

//import svg
import logo from './logo.svg';

import svg_height from './assets/images/svg/height-icon.svg';
import svg_weight from './assets/images/svg/weight-icon.svg';
import svg_eye from './assets/images/svg/eye-icon.svg';
import svg_hair from './assets/images/svg/hair-icon.svg';
//import svg_lang from './assets/images/svg/characteristics.svg';
import svg_lang from './assets/images/chracteristics.png';

import svg_donor from './assets/images/svg/donor-icon.svg';
//import svg_cycle from './assets/images/svg/share-cycles-icon.svg';
import svg_cycle from './assets/images/shared-cycle-interlaced.png';
import svg_cycle_mobile from './assets/images/shared-cycle_small.png';
import svg_frozen from './assets/images/svg/frozen-icon.svg';

import svg_photos from './assets/images/svg/photos-icon.svg';
import svg_about from './assets/images/svg/about-icon.svg';
import svg_education from './assets/images/svg/education-icon.svg';
import svg_health from './assets/images/svg/health-icon.svg';
import svg_donation from './assets/images/svg/donations-icon.svg';
import svg_info from './assets/images/svg/info-icon.svg';

import svg_photos_hover from './assets/images/svg/photos-icon-hover.svg';
import svg_about_hover from './assets/images/svg/about-icon-hover.svg';
import svg_education_hover from './assets/images/svg/education-icon-hover.svg';
import svg_health_hover from './assets/images/svg/health-icon-hover.svg';
import svg_donation_hover from './assets/images/svg/donations-icon-hover.svg';
import img_placeholder from './assets/images/donor_placeholder.jpg';

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
};

var toke = '';

class TProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active_tab: 0,
            load_data: 0,            
            photos_count: 1,
            is_lightbox: false,
            lightbox_photoIndex: 0,
            bookmark: 0,
            reserves: 0,
            reserved: false,
            modalInterestedOpen: false,
            modalBookmarkOpen: false,
            modalReserveOpen: false,
            modalUnreserveOpen: false,
            about_more: false,
            ticket_submit: false
        }
        this.p_info = '';
        this.p_id = 0;
        this.api_message = '';
        this.bookmarked = 0;

        this.openModalInterested = this.openModalInterested.bind(this);
        this.afterOpenModalInterested = this.afterOpenModalInterested.bind(this);
        this.closeModalInterested = this.closeModalInterested.bind(this);

        this.openModalBookmark = this.openModalBookmark.bind(this);
        this.afterOpenModalBookmark = this.afterOpenModalBookmark.bind(this);
        this.closeModalBookmark = this.closeModalBookmark.bind(this);

        this.openModalReserve = this.openModalReserve.bind(this);
        this.afterOpenModalReserve = this.afterOpenModalReserve.bind(this);
        this.closeModalReserve = this.closeModalReserve.bind(this);   
        
        this.openModalUnreserve = this.openModalUnreserve.bind(this);
        this.afterOpenModalUnreserve = this.afterOpenModalUnreserve.bind(this);
        this.closeModalUnreserve = this.closeModalUnreserve.bind(this);  

        this.popup_html = '';
        this.cta_html = '';
        this.notificationDOMRef = React.createRef();
    }
    
    componentWillMount() {
        var params = queryString.parse(this.props.props.location.search);
        this.p_id = params.id;   
        F_UTIL.check_login();

        document.title =  global.title;
    }
 
    componentDidMount() {
       // document.body.addEventListener('contextmenu', this.onImgMouseDown);
        this.get_donor_info();
    }

    addNotification(type, msg) {
        this.notificationDOMRef.current.addNotification({
            message: msg,
            type: type,
            insert: "top",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 2000 },
            dismissable: { click: true }
          });
    }

    filterValues() {
        if (this.p_info.medicalproblems == 'N/A' || this.p_info.medicalproblems == 'n/a') {
            this.p_info.medicalproblems = '';
        }

        if (this.p_info.currentmeds == 'N/A' || this.p_info.currentmeds == 'n/a') {
            this.p_info.currentmeds = '';
        }
        
        if (this.p_info.yearmeds == 'N/A' || this.p_info.yearmeds == 'n/a') {
            this.p_info.yearmeds = '';
        }
        
        if (this.p_info.hospitalizations == 'N/A' || this.p_info.hospitalizations == 'n/a') {
            this.p_info.hospitalizations = '';
        }
        
        if (this.p_info.psychfacility == 'N/A' || this.p_info.psychfacility == 'n/a') {
            this.p_info.psychfacility = '';
        }  
        
        if (this.p_info.counseling == 'N/A' || this.p_info.counseling == 'n/a') {
            this.p_info.counseling = '';     
        }  

        if (this.p_info.birthcontrol == 'N/A' || this.p_info.birthcontrol == 'n/a') {
            this.p_info.birthcontrol = '';
        }   
        if (this.p_info.lastpapsmear == 'N/A' || this.p_info.lastpapsmear == 'n/a') {
            this.p_info.lastpapsmear = '';
        }   
        if (this.p_info.transfusion == 'N/A' || this.p_info.transfusion == 'n/a') {
            this.p_info.transfusion = '';
        }   
        if (this.p_info.twinstriplets == 'N/A' || this.p_info.twinstriplets == 'n/a') {
            this.p_info.twinstriplets = '';
        }   
        if (this.p_info.hivaids == 'N/A' || this.p_info.hivaids == 'n/a') {
            this.p_info.hivaids = '';
        }   
        if (this.p_info.pregcomplication == 'N/A' || this.p_info.pregcomplication == 'n/a') {
            this.p_info.pregcomplication = '';
        }   
        if (this.p_info.dom == 'N/A' || this.p_info.dom == 'n/a') {
            this.p_info.dom = '';
        }   
        if (this.p_info.subabuse == 'N/A' || this.p_info.subabuse == 'n/a') {
            this.p_info.subabuse = '';
        }   
        if (this.p_info.historymigraine == 'N/A' || this.p_info.historymigraine == 'n/a') {
            this.p_info.historymigraine = '';
        }   
        if (this.p_info.glasses == 'N/A' || this.p_info.glasses == 'n/a') {
            this.p_info.glasses = '';
        }   
        if (this.p_info.cancer == 'N/A' || this.p_info.cancer == 'n/a') {
            this.p_info.cancer = '';
        }   
        if (this.p_info.downs == 'N/A' || this.p_info.downs == 'n/a') {
            this.p_info.downs = '';
        }   
        if (this.p_info.mental == 'N/A' || this.p_info.mental == 'n/a') {
            this.p_info.mental = '';
        }   
        if (this.p_info.siezure == 'N/A' || this.p_info.siezure == 'n/a') {
            this.p_info.siezure = '';
        }   
        if (this.p_info.muscular == 'N/A' || this.p_info.muscular == 'n/a') {
            this.p_info.muscular = '';
        }   
        
        if (this.p_info.deafness == 'N/A' || this.p_info.deafness == 'n/a') {
            this.p_info.deafness = '';
        }  
        if (this.p_info.blindness == 'N/A' || this.p_info.blindness == 'n/a') {
            this.p_info.blindness = '';
        }  
        if (this.p_info.glaucoma == 'N/A' || this.p_info.glaucoma == 'n/a') {
            this.p_info.glaucoma = '';
        }  
        if (this.p_info.sighted == 'N/A' || this.p_info.sighted == 'n/a') {
            this.p_info.sighted = '';
        }  
        if (this.p_info.depression == 'N/A' || this.p_info.depression == 'n/a') {
            this.p_info.depression = '';
        }  
        if (this.p_info.birthdefect == 'N/A' || this.p_info.birthdefect == 'n/a') {
            this.p_info.birthdefect = '';
        }  
        if (this.p_info.heartproblems == 'N/A' || this.p_info.heartproblems == 'n/a') {
            this.p_info.heartproblems = '';
        }  
        if (this.p_info.miscarriage == 'N/A' || this.p_info.miscarriage == 'n/a') {
            this.p_info.miscarriage = '';
        }  
        if (this.p_info.skindiscoloration == 'N/A' || this.p_info.skindiscoloration == 'n/a') {
            this.p_info.skindiscoloration = '';
        }  
        if (this.p_info.hemophilia == 'N/A' || this.p_info.hemophilia == 'n/a') {
            this.p_info.hemophilia = '';
        }  
        if (this.p_info.ulcer == 'N/A' || this.p_info.ulcer == 'n/a') {
            this.p_info.ulcer = '';
        }  
        if (this.p_info.stroke == 'N/A' || this.p_info.stroke == 'n/a') {
            this.p_info.stroke = '';
        }  
        if (this.p_info.ovariancyst == 'N/A' || this.p_info.ovariancyst == 'n/a') {
            this.p_info.ovariancyst = '';
        }  
        if (this.p_info.uterinefibroid == 'N/A' || this.p_info.uterinefibroid == 'n/a') {
            this.p_info.uterinefibroid = '';
        }  
        if (this.p_info.hyperactivity == 'N/A' || this.p_info.hyperactivity == 'n/a') {
            this.p_info.hyperactivity = '';
        }       
        
        if (this.p_info.alcoholism == 'N/A' || this.p_info.alcoholism == 'n/a') {
            this.p_info.alcoholism = '';
        }  
        if (this.p_info.learningdisability == 'N/A' || this.p_info.learningdisability == 'n/a') {
            this.p_info.learningdisability = '';
        }  
        if (this.p_info.drugaddiction == 'N/A' || this.p_info.drugaddiction == 'n/a') {
            this.p_info.drugaddiction = '';
        }  
        if (this.p_info.diabetes == 'N/A' || this.p_info.diabetes == 'n/a') {
            this.p_info.diabetes = '';
        }  
        if (this.p_info.autism == 'N/A' || this.p_info.autism == 'n/a') {
            this.p_info.autism = '';
        }                                          
    }

    get_donor_info() {        
        var url = global.api_url + 'donor/info/' + this.p_id;
        console.log('Call API:', url);

        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json', 
            data: {rid: global.r_id},
            cache: false,
            crossDomain: true,
            success: function(response) {
                
                if (response.success == true) {
                    //var info = JSON.parse(response);
                    this.p_info = response.info;
                    this.filterValues();
                    this.setState({load_data: 1, 
                            bookmark: this.p_info.bookmark, 
                            reserves: this.p_info.reserves,
                            reserved: this.p_info.reserved});
                    console.log('Ajax API:', response.id);
                } else {
                    this.api_message = response.message;
                    this.setState({load_data: -1});                    
                    console.log('Ajax API failed:', this.api_message);
                    F_UTIL.goto_login();
                }
            }.bind(this)
        });
    }
    
    openModalInterested() {
        this.setState({modalInterestedOpen: true, ticket_submit:false});
    }
    
    afterOpenModalInterested() {
    // references are now sync'd and can be accessed.
        //this.subtitle.style.color = '#f00';
    }
    
    closeModalInterested() {
        this.setState({modalInterestedOpen: false, ticket_submit:false});
    }

    openModalBookmark() {
        this.setState({modalInterestedOpen: true});
    }
    
    afterOpenModalBookmark() {
    // references are now sync'd and can be accessed.
        //this.subtitle.style.color = '#f00';
    }
    
    closeModalBookmark() {
        this.setState({modalBookmarkOpen: false});
    }

    openModalReserve() {
        this.setState({modalReserveOpen: true});
    }
    
    openModalUnreserve() {
        this.setState({modalUnreserveOpen: true});
    }

    afterOpenModalReserve() {
    // references are now sync'd and can be accessed.
        //this.subtitle.style.color = '#f00';
    }
 
    afterOpenModalUnreserve() {
        // references are now sync'd and can be accessed.
        //this.subtitle.style.color = '#f00';
    }

    closeModalReserve() {
        this.setState({modalReserveOpen: false});
    }

    closeModalUnreserve() {
        this.setState({modalUnreserveOpen: false});
    }

    submitTicket(e) {
        e.preventDefault();

        if ($('#email').val() == '') {
            $('#email').focus();
            this.addNotification('danger', 'Input Email');
            return;
        }

        if ($('#questions').val() == '') {
            $('#questions').focus();
            this.addNotification('danger', 'Input Questions');
            return;
        }

        var url;
        console.log('Click Interested Form Submit');
        url = global.api_url + 'recipient/submit_interested_ticket/' + global.r_id;


        var data =  $('#interested-form').serializeArray();
        data.push({name: 'token', value: global.token});
        data.push({name: 'donor_id', value: this.p_id});


        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json', 
            data: data, 
            cache: false,
            crossDomain: true,
            success: function(response) {
                
                if (response.success == true) {
                    this.setState({ticket_submit: true});
                } else {
                    //this.api_message = response.message;
                    //console.log('Ajax API failed:', this.api_message);
                    //this.addNotification('danger', this.api_message);
                    //F_UTIL.goto_login();
                }
            }.bind(this)
        });    



        this.setState({ticket_submit: true});
    }

    updateBookmark(e) {
        console.log('Click Bookmark Icon');

        const {bookmark} = this.state;
        this.setState({modalBookmarkOpen: 0});

        var url;
        console.log('Click Bookmark:' + bookmark);
        if (bookmark == true)
            url = global.api_url + 'recipient/release_bookmark/' + global.r_id;
        else 
            url = global.api_url + 'recipient/set_bookmark/' + global.r_id;

        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json', 
            data: {token:global.token, donor_id: this.p_id},
            cache: false,
            crossDomain: true,
            success: function(response) {
                
                if (response.success == true) {
                    var new_val = !bookmark;
                    console.log('Update Bookmark:' + new_val);
                    this.setState({bookmark:!bookmark});

                    //var url = '/account';
                    //window.location = url;
                    if (new_val)
                        this.addNotification('success', 'Bookmark Successfully');
                    else 
                        this.addNotification('success', 'Clear Bookmark Successfully');

                } else {
                    //this.api_message = response.message;
                    //console.log('Ajax API failed:', this.api_message);
                    //this.addNotification('danger', this.api_message);
                    F_UTIL.goto_login();
                }
            }.bind(this)
        });    

    }

    updateReserve(e) {
        
        const {reserved} = this.state;
        this.setState({modalReserveOpen: false, modalUnreserveOpen:false});
 
        var url;
        console.log('Click Rserve:' + reserved + ',Rid:' + global.r_id + ',Did:' + this.p_id);
        if (reserved == true)
            url = global.api_url + 'recipient/release_reserve/' + global.r_id;
        else 
            url = global.api_url + 'recipient/set_reserve/' + global.r_id;

        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json', 
            data: {token:global.token, donor_id: this.p_id},
            cache: false,
            crossDomain: true,
            success: function(response) {
                
                if (response.success == true) {
                    var new_val = !reserved;
                    console.log('Update Reserve:' + new_val);
                    this.setState({reserved:!reserved});

                    //var url = '/account';
                    //window.location = url;
                    if (new_val)
                        this.addNotification('success', 'Reserved Successfully');
                    else 
                        this.addNotification('success', 'Unreserved Successfully');
                } else {
                    //this.api_message = response.message;
                    //console.log('Ajax API failed:', this.api_message);
                    //this.addNotification('danger', this.api_message);
                    F_UTIL.goto_login();
                }
            }.bind(this)
        });    

    }

    renderModalInterested(){
        const {ticket_submit} = this.state;
        return (
            <Modal
                isOpen={this.state.modalInterestedOpen} 
                onAfterOpen={this.afterOpenModalInterested} 
                onRequestClose={this.closeModalInterested} 
                style={customStyles} 
                contentLabel="Example Modal">
                <div className="modal_box">
                    <i className="fa fa-times" aria-hidden="true" onClick={this.closeModalInterested}></i>
                    
                    <h3 className="popup-title">Ask us a Question</h3>

                    <div className="popup-content">
                    {
                        ticket_submit == true?
                        <p className="ticket-title">
                        Thank you for contacting us.<br/>
                        You are very important to us, all information received will always remain confidential.
                        We will contact you as soon as we review your message.
                        </p>
                        :
                        <form onSubmit={this.submitTicket.bind(this)} className="interested-form" id="interested-form">
                            
                            <input type="text" 
                                className="sform-control4" 
                                name="email" 
                                placeholder="Your Email Address*" 
                                id="email" 
                                defaultValue={global.email}></input>
                            <textarea 
                                className="sform-control4" 
                                placeholder="Your Question:" 
                                rows="5" 
                                id="questions" 
                                name="questions" />
                            <button type="submit"  className="btn btn-cta">Submit</button>
                        </form>
                    }
                    </div>

                    <div className="row mt-5">
                        <div className="col-md-6">
                            <div class="addr-row-frame">
                                <h4 className="addr-title1">WEST COAST OFFICE</h4>
                                <p className="addr-label">15303 Ventura Blvd., Building C, Suite 640</p>
                                <p className="addr-label">Sherman Oaks, CA 91403</p>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div class="addr-row-frame">
                                <div class="addr-row">
                                    <div class="left"><span class="addr-title">p:</span></div>
                                    <div class="right"><a href="tel:8183850951">(818) 385.0950</a></div>    
                                </div>
                                <div class="addr-row">
                                    <div class="left"><span class="addr-title">f:</span></div>
                                    <div class="right"><a href="tel:8183850951">(818) 385.0951</a></div>
                                </div>
                                <div class="addr-row">
                                    <div class="left"><span class="addr-title">e:</span></div>
                                    <div class="right"><a href="mailto:info@eggdonor.com">info@eggdonor.com</a></div>
                                </div>
                            </div>

                        </div>
                    </div>





                </div>
          </Modal>            
        ) ;
    }

    renderModalBookmark(){
        return (
            <Modal
                isOpen={this.state.modalBookmarkOpen}
                onAfterOpen={this.afterOpenModalBookmark}
                onRequestClose={this.closeModalBookmark}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="modal_box">
                    <i className="fa fa-times" aria-hidden="true" onClick={this.closeModalBookmark}></i>
                    
                    <h2 className="modal_title">{this.popup_html}</h2>
                    <div className="modal_btns">
                        <button className="btn btn-white btn-prompt-cancel" onClick={this.closeModalBookmark}>CANCEL</button>
                        <button className="btn btn-green btn-prompt-remove" onClick={this.updateBookmark.bind(this)}>{this.cta_html}</button>    
                                    
                    </div>
                </div>
          </Modal>            
        ) ;
    }

    renderModalReserve(){
        return (
            <Modal
                isOpen={this.state.modalReserveOpen}
                onAfterOpen={this.afterOpenModalReserve}
                onRequestClose={this.closeModalReserve}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="modal_box">
                    <i className="fa fa-times" aria-hidden="true" onClick={this.closeModalReserve}></i>
                    
                    <h2 className="modal_title">Are you sure you want to reserve this donor?</h2>
                    <div className="modal_btns">
                        <button className="btn btn-white btn-prompt-cancel" onClick={this.closeModalReserve}>CANCEL</button>
                        <button className="btn btn-green btn-prompt-remove" onClick={this.updateReserve.bind(this)}>Reserve</button>    
                                    
                    </div>
                </div>
          </Modal>            
        ) ;
    }

    renderModalUnreserve(){
        return (
            <Modal
                isOpen={this.state.modalUnreserveOpen}
                onAfterOpen={this.afterOpenModalUnreserve}
                onRequestClose={this.closeModalUnreserve}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="modal_box">
                    <i className="fa fa-times" aria-hidden="true" onClick={this.closeModalUnreserve}></i>
                    
                    <h2 className="modal_title">Are you sure you want to unreserve this donor?</h2>
                    <div className="modal_btns">
                        <button className="btn btn-white btn-prompt-cancel" onClick={this.closeModalUnreserve}>CANCEL</button>
                        <button className="btn btn-green btn-prompt-remove" onClick={this.updateReserve.bind(this)}>Unreserve</button>    
                                    
                    </div>
                </div>
          </Modal>            
        ) ;
    }

    onChangeTab(tab, e) {
        e.preventDefault();
        console.log('Tab:', tab);
        this.setState({active_tab: tab});
        
    }

    onClickInterested(e) {
        e.preventDefault();
        this.setState({modalInterestedOpen: true});
    }

    onClickBookmark(e) {
        e.preventDefault();       
        
        const {bookmark} = this.state;
        
        if (bookmark == false) {
            console.log('Unmarked Donor');
            //return;
            this.popup_html = 'Are you sure you want to add this donor to your bookmarks?';
            this.cta_html = 'ADD BOOKMARK';
            
        } else {
            this.popup_html = 'Are you sure you want to remove this donor from your bookmarks?';
            this.cta_html = 'REMOVE BOOKMARK';
        }
        
        this.setState({modalBookmarkOpen: true});
        return;
    }

    
    onClickReserve(e) {
        e.preventDefault();       
        
        this.setState({modalReserveOpen: true});
        return;
    }

    onClickUnreserve(e) {
        e.preventDefault();       
        
        this.setState({modalUnreserveOpen: true});
        return;
    }

    onClickGridImage(img_index, e) {
        e.preventDefault();
        console.log("Image Clicked:", img_index);
        this.setState({ is_lightbox: true , lightbox_photoIndex:img_index})
    }
    onImgContextMenu(e) {
        console.log('Context Menu');
        e.preventDefault();
        return false;
    }

    onImgMouseDown(e) {
        console.log('Mouse Donwload:', e.button);
        e.preventDefault();
        if(e.button == 2) { // right click
            
            console.log('Mouse Donwload Do nothing');
            return false; // do nothing!
        }
        return false;

    }

    onLoadMore(e) {
        
        e.preventDefault();
        const {photos_count} = this.state;
        var count = photos_count;
        console.log('Load More clicked,', count);
        count = count + 1;
        this.setState({photos_count:count})
    }

    onLoadAboutMore(e) {
        
        e.preventDefault();
        const {about_more} = this.state;
       
        this.setState({about_more:true})
    }

    render_tab(){
        const {active_tab} = this.state;

        if (active_tab == 0){
            return this.render_tab_photos();
        } else if (active_tab == 1){
            return this.render_tab_about();
        } else if (active_tab == 2){
            return this.render_tab_education();
        } else if (active_tab == 3){
            return this.render_tab_health();
        } else if (active_tab == 4){
            return this.render_tab_donation();
        }
    }

    render_tab_nav() {
        var icon_photos = svg_photos;
        var icon_about = svg_about;
        var icon_education = svg_education;
        var icon_health = svg_health;
        var icon_donation = svg_donation;

        var active_photo = 'tab-nav-item';
        var active_about = 'tab-nav-item';
        var active_education = 'tab-nav-item';
        var active_health = 'tab-nav-item';
        var active_donation = 'tab-nav-item';

        const {active_tab} = this.state;

        if (active_tab == 0){
            icon_photos = svg_photos_hover;
            active_photo = 'tab-nav-item active';
        } else if (active_tab == 1){
            icon_about = svg_about_hover;
            active_about = 'tab-nav-item active';
        } else if (active_tab == 2){
            icon_education = svg_education_hover;
            active_education = 'tab-nav-item active';
        } else if (active_tab == 3){
            icon_health = svg_health_hover;
            active_health = 'tab-nav-item active';
        } else if (active_tab == 4){
            icon_donation = svg_donation_hover;
            active_donation = 'tab-nav-item active';
        }

        return (
            <ul className="profile-nav">
            <li className={active_photo}>
                <a href="#" id="tab-nav-photos" onClick={this.onChangeTab.bind(this, 0)}>
                    <img className="profile-tab-icon" src={icon_photos}></img> 
                    <div className="profile-nav-name">Photos</div>
                </a>
                
            </li>
            <li className={active_about}>
                <a href="#" id="tab-nav-about" onClick={this.onChangeTab.bind(this, 1)}>
                    <img className="profile-tab-icon" src={icon_about}></img> 
                    <div className="profile-nav-name">About</div>
                </a>
            </li>
            <li className={active_education}>
                <a href="#" id="tab-nav-education" onClick={this.onChangeTab.bind(this, 2)}>
                    <img className="profile-tab-icon" src={icon_education}></img> 
                    <div className="profile-nav-name">Education</div>
                </a>
            </li>
            <li className={active_health}>
                <a href="#" id="tab-nav-health" onClick={this.onChangeTab.bind(this, 3)}>
                    <img className="profile-tab-icon" src={icon_health}></img> 
                    <div className="profile-nav-name">Health</div>
                </a>
            </li>
            <li className={active_donation}>
                <a href="#" id="tab-nav-donation" onClick={this.onChangeTab.bind(this, 4)}>
                    <img className="profile-tab-icon" src={icon_donation}></img> 
                    <div className="profile-nav-name">Donations</div>
                </a>
            </li>
        </ul>
        )
    }

    render_tab_photos() {
        const {photos_count, is_lightbox, lightbox_photoIndex} = this.state;
        const { width } = this.props;
        var len = 0;
        if (this.p_info.photos)
            len = this.p_info.photos.length;

        if (len == 0)
            return;
        var i=0;
        var items = [];
        var images = [];

        var count = photos_count * 15;
        console.log('Render Photos:', count);
        for(i =0;i<len;i++){
            var img = this.p_info.photos[i];
            if (img != undefined) {
                var img_url = process.env.REACT_APP_IMAGE_URL + "/images/donors2/" + this.p_id + "/" + img.id;
                items.push(
                    <div className="grid-img-item" onClick={this.onClickGridImage.bind(this, i)} key={i}> 
                        <p className="grid-caption">
                            <span>{img.caption}</span>
                        </p>
                        <img src={img_url} className="grid-img" ></img>
                    </div>);
                images.push({img});
            }
                
        }
        
        var col_width = '25%';
        if (isMobile) {
            col_width = '50%';
        }
            
        var img_url = process.env.REACT_APP_IMAGE_URL + "/images/donors2/" +  this.p_id + "/" +  images[lightbox_photoIndex].img.id;
        var next_img = process.env.REACT_APP_IMAGE_URL + "/images/donors2/" +  this.p_id + "/" +  images[(lightbox_photoIndex + 1) % images.length].img.id;
        var prev_img = process.env.REACT_APP_IMAGE_URL + "/images/donors2/" +  this.p_id + "/" +  images[(lightbox_photoIndex + images.length - 1) % images.length].img.id;
        var img_caption = images[lightbox_photoIndex].img.caption;
        var delay = 200;
        return (
            <div>
                <StackGrid 
                appearDelay={delay}  
                onContextMenu={this.onImgContextMenu.bind(this)} 
                columnWidth={col_width} monitorImagesLoaded={true} rtl={false}>
                {items}
                </StackGrid>           
                {is_lightbox && (
                <Lightbox
                    mainSrc={img_url}
                    nextSrc={next_img}
                    prevSrc={prev_img}
                    onCloseRequest={() => this.setState({ is_lightbox: false })} 
                    imageTitle={img_caption} 
                    onMovePrevRequest={() =>
                    this.setState({
                        lightbox_photoIndex: (lightbox_photoIndex + images.length - 1) % images.length,
                    })
                    }
                    onMoveNextRequest={() =>
                    this.setState({
                        lightbox_photoIndex: (lightbox_photoIndex + 1) % images.length,
                    })
                    }
                />
                )}
            </div>
            );

    }

    render_about_more_button() {
        return (
            <div className="row info_row_gap text-center" id="content-loadmore">
                <button className="btn btn-more" onClick={this.onLoadAboutMore.bind(this)}>Load More</button>
            </div>            
        )
    }

    render_tab_about() {
        const {about_more} = this.state;
        var video_url = this.p_info.videos;

        var extra_content = [];
        if (about_more == true) {
            extra_content.push(
                <div className="row info_row_gap mt-5">
                    <div className="col-xs-12 col-md-12 ">
                        <div className="profile-user-heading pl-3">More About Your Donor</div>
                        {
                            this.p_info.arttalent?
                            <div className="profile-info-row text-left pl-3">
                                <span className="profile-info-name">Artistic Talents</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.arttalent}</span>                                      
                            </div>                             
                            :""
                        }

                        {
                            this.p_info.philosophy?
                            <div className="profile-info-row text-left pl-3">
                                <span className="profile-info-name">Life Philosophy</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.philosophy}</span>                                      
                            </div>                             
                            :""
                        }

                        {
                            this.p_info.valuemost?
                            <div className="profile-info-row text-left pl-3">
                                <span className="profile-info-name">I Most Value</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.valuemost}</span>                                      
                            </div>                             
                            :""
                        }

                        {
                            this.p_info.proudof?
                            <div className="profile-info-row text-left pl-3">
                                <span className="profile-info-name">I Am Proud Of</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.proudof}</span>                                      
                            </div>                             
                            :""
                        }

                        {
                            this.p_info.believe?
                            <div className="profile-info-row text-left pl-3">
                                <span className="profile-info-name">I Believe That</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.believe}</span>                                      
                            </div>                             
                            :""
                        }
   
                        {
                            this.p_info.dislike?
                            <div className="profile-info-row text-left pl-3">
                                <span className="profile-info-name">I Disklike</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.dislike}</span>                                      
                            </div>                             
                            :""
                        }

                        {
                            this.p_info.immediategoals?
                            <div className="profile-info-row text-left pl-3">
                                <span className="profile-info-name">My Immediate Goals Are</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.immediategoals}</span>                                      
                            </div>                             
                            :""
                        }

                        {
                            this.p_info.futureplans?
                            <div className="profile-info-row text-left pl-3">
                                <span className="profile-info-name">The Future Plans Are</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.futureplans}</span>                                      
                            </div>                             
                            :""
                        }
 
                        {
                            this.p_info.childhoodexp?
                            <div className="profile-info-row text-left pl-3">
                                <span className="profile-info-name">Most Memorable Childhood Experience</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.childhoodexp}</span>                                      
                            </div>                              
                            :""
                        }

                        {
                            this.p_info.pets?
                            <div className="profile-info-row text-left pl-3">
                                <span className="profile-info-name">The Pets I Have Are</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.pets}</span>                                      
                            </div>                              
                            :""
                        }

                    </div>
                </div>                
            );

            extra_content.push(
                <div className="row info_row_gap">
                    <div className="col-xs-12 col-md-12 ">
                        <div className="profile-user-heading pl-3">Your Donor's Favorite Things</div>
                        {
                            this.p_info.favfood?
                            <div className="profile-info-row text-left pl-3">
                                <span className="profile-info-name">Food</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.favfood}</span>                                      
                            </div> 
                            :""
                        }

                        {
                            this.p_info.favcolor?
                            <div className="profile-info-row text-left pl-3">
                                <span className="profile-info-name">Color</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.favcolor}</span>                                      
                            </div> 
                            :""
                        }

                        {
                            this.p_info.favseason?
                            <div className="profile-info-row text-left pl-3">
                                <span className="profile-info-name">Season</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.favseason}</span>                                      
                            </div> 
                            :""
                        }

                        {
                            this.p_info.favholiday?
                            <div className="profile-info-row text-left pl-3">
                                <span className="profile-info-name">Holiday</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.favholiday}</span>                                      
                            </div> 
                            :""
                        }

                        {
                            this.p_info.favsports?
                            <div className="profile-info-row text-left pl-3">
                                <span className="profile-info-name">Sport</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.favsports}</span>                                      
                            </div> 
                            :""
                        }

                        {
                            this.p_info.favbook?
                            <div className="profile-info-row text-left pl-3">
                                <span className="profile-info-name">Book</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.favbook}</span>                                      
                            </div> 
                            :""
                        }

                        {
                            this.p_info.favsong?
                            <div className="profile-info-row text-left pl-3">
                                <span className="profile-info-name">Song</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.favsong}</span>                                      
                            </div> 
                            :""
                        }

                        {
                            this.p_info.favtv?
                            <div className="profile-info-row text-left pl-3">
                                <span className="profile-info-name">TV Program</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.favtv}</span>                                      
                            </div>  
                            :""
                        }

                        {
                            this.p_info.favsinger?
                            <div className="profile-info-row text-left pl-3">
                                <span className="profile-info-name">Singer/Group</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.favsinger}</span>                                      
                            </div>  
                            :""
                        }

                        {   
                            this.p_info.favmovie?
                            <div className="profile-info-row text-left pl-3">
                                <span className="profile-info-name">Movie</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.favmovie}</span>                                      
                            </div> 
                            :""
                        }
 
                        {
                            this.p_info.favhobbie?
                            <div className="profile-info-row text-left pl-3">
                                <span className="profile-info-name">Hobbies</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.favhobbie}</span>                                      
                            </div>    
                            :"" 
                        }

                        {
                            this.p_info.messageparents?
                            <div className="profile-info-row text-left pl-3 pt-5">
                                <span className="profile-info-name">A Message to the Intended Parent(s)</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.messageparents}</span>                                      
                            </div> 
                            :""
                        }                                          
                    </div>
                </div>                
            );            

        }
        return (
            <div id="tab-about">

            <div className="row">
                <div className="col-xs-12 col-md-6">
                    <div className="profile-user-heading pl-3">Lifestyle</div>
                    {
                        this.p_info.maritalstatus?
                        <div className="profile-info-row text-left pl-3">
                            <span className="profile-info-name ">Marital Status</span>
                            <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.maritalstatus}</span>                                       
                        </div>                         
                        :""
                    }
     
                    {
                        this.p_info.occupation?
                        <div className="profile-info-row text-left pl-3">
                            <span className="profile-info-name">Occupation</span>
                            <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.occupation}</span>                                       
                        </div>                          
                        :""
                    }
    
                    {
                        this.p_info.arrested?
                        <div className="profile-info-row text-left pl-3">
                            <span className="profile-info-name">Arrested</span>
                            <span className="profile-info-value pl-1" >&nbsp;&nbsp;{this.p_info.arrested}
                                {this.p_info.arrestedexp?",":""}
                                {this.p_info.arrestedexp}                        
                            </span>                                       
                        </div>                         
                        :""
                    }

                    {
                        this.p_info.smoker?
                        <div className="profile-info-row text-left pl-3">
                            <span className="profile-info-name">Smoke</span>
                            <span className="profile-info-value pl-1" >&nbsp;&nbsp;{this.p_info.smoker}</span>                                       
                        </div>                         
                        :""
                    }
 
                    {
                        this.p_info.drinkalcohol?
                        <div className="profile-info-row text-left pl-3">
                            <span className="profile-info-name">Drink</span>
                            <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.drinkalcohol}</span>                                       
                        </div>                        
                        :""
                    }
  
                    {
                        this.p_info.druguse?
                        <div className="profile-info-row text-left pl-3">
                            <span className="profile-info-name">Illicit Drug Use</span>
                            <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.druguse}</span>                                        
                        </div>                         
                        :""
                    }
 
                    {
                        this.p_info.tattoo?
                        <div className="profile-info-row text-left pl-3">
                            <span className="profile-info-name">Recent Tattoos</span>
                            <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.tattoo}</span>                                       
                        </div>                        
                        :""
                    }                    
  
                    {
                        this.p_info.piercing?
                        <div className="profile-info-row text-left pl-3">
                            <span className="profile-info-name">Recent Piercings</span>
                            <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.piercing}</span>                                       
                        </div>                        
                        :""
                    }

                    {
                        this.p_info.sexorient?
                        <div className="profile-info-row text-left pl-3">
                            <span className="profile-info-name">Sexual Orientation</span>
                            <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.sexorient}</span>                                         
                        </div>                          
                        :""
                    }

                    {
                        this.p_info.otherpartners?
                        <div className="profile-info-row text-left pl-3">
                            <span className="profile-info-name">Multiple Sex Partners</span>
                            <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.otherpartners}</span>                                       
                        </div>                          
                        :""
                    }
                    
                </div>
                <div className="col-xs-12 col-md-6">
                    <div className="profile-user-heading pl-3">Family Background</div>
                    {
                        this.p_info.birthreligion?
                        <div className="profile-info-row text-left pl-3">
                            <span className="profile-info-name">Birth Religion</span>
                            <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.birthreligion}</span>                                      
                        </div>                         
                        :""
                    }

                    {
                        this.p_info.father1?
                        <div className="profile-info-row text-left pl-3">
                            <span className="profile-info-name">Father's Mother</span>
                            <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.father1}</span>                                       
                        </div>                         
                        :""
                    }

                    {
                        this.p_info.mother1?
                        <div className="profile-info-row text-left pl-3">
                            <span className="profile-info-name">Father's Father</span>
                            <span className="profile-info-value pl-1" >&nbsp;&nbsp;{this.p_info.father2}</span>                                   
                        </div>                         
                        :""
                    }

                    {
                        this.p_info.father2?
                        <div className="profile-info-row text-left pl-3">
                            <span className="profile-info-name">Mother's Mother</span>
                            <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.mother1} </span>                                      
                        </div>                         
                        :""
                    }

                    {
                        this.p_info.mother2?
                        <div className="profile-info-row text-left pl-3">
                            <span className="profile-info-name">Mother's Father</span>
                            <span className="profile-info-value pl-1" >&nbsp;&nbsp;{this.p_info.mother2}</span>    
                        </div>                         
                        :""
                    }
                                                                                                
                </div>
            </div>

            {
                this.p_info.personality?
                <div className="row info_row_gap">
                    <div className="col-xs-12 col-md-12 ">
                        <div className="profile-user-heading pl-3">Personality</div>
                        <p className="profile-info-content text-left pl-3">
                        {this.p_info.personality}
                        </p>
                    </div>
                </div>                
                :""
            }

            {
                this.p_info.hobbies?
                <div className="row info_row_gap">
                    <div className="col-xs-12 col-md-12 ">
                        <div className="profile-user-heading pl-3">Hobbies</div>
                        <p className="profile-info-content text-left pl-3">
                        {this.p_info.hobbies}
                        </p>
                    </div>
                </div>                
                :""
            }

            {
                this.p_info.goalsinlife?
                <div className="row info_row_gap">
                    <div className="col-xs-12 col-md-12 ">
                        <div className="profile-user-heading pl-3">What are your goals?</div>
                        <p className="profile-info-content text-left pl-3">
                        {this.p_info.goalsinlife}
                        </p>
                    </div>
                </div>                
                :""
            }

            {
                this.p_info.whydonate?
                <div className="row info_row_gap">
                    <div className="col-xs-12 col-md-12">
                        <div className="profile-user-heading pl-3">Why do you want to be a donor?</div>
                        <p className="profile-info-content text-left pl-3">
                            {this.p_info.whydonate}
                        </p> 
                    </div>
                </div>                   
                :""
            }
                                             
            {
                about_more == false
                ?this.render_about_more_button()
                :extra_content
            }
                                                   
        </div>            
        )
    }

    render_tab_education() {
        return (
            <div id="tab-education">
            <div className="row">
                <div className="col-xs-12 col-md-6">
                    <div className="profile-user-heading pl-3">Education History</div>
                    {
                        this.p_info.educationlevel?
                        <div className="profile-info-row  text-left pl-3">
                            <span className="profile-info-name">Education Level</span>
                            <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.educationlevel}</span>
                        </div>   
                        :""                        
                    }
   

                    {
                        this.p_info.actscore?
                        <div className="profile-info-row text-left pl-3">
                            <span className="profile-info-name">ACT Score</span>
                            <span className="profile-info-value pl-1" >&nbsp;&nbsp;{this.p_info.actscore}</span>                                         
                        </div>  
                        :""                          
                    }


                    {
                        this.p_info.satscore?
                        <div className="profile-info-row text-left pl-3">
                            <span className="profile-info-name">SAT Score</span>
                            <span className="profile-info-value pl-1" >&nbsp;&nbsp;{this.p_info.satscore}</span>                                         
                        </div>  
                        :""                          
                    }


                    {
                        this.p_info.collegegpa?
                        <div className="profile-info-row text-left pl-3">
                            <span className="profile-info-name">College GPA</span>
                            <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.collegegpa}</span>                                         
                        </div>                          
                        :""  
                    }


                    {
                        this.p_info.iqscore?
                        <div className="profile-info-row text-left pl-3">
                            <span className="profile-info-name">IQ</span>
                            <span className="profile-info-value pl-1" >&nbsp;&nbsp;{this.p_info.iqscore}</span>                                         
                        </div>                          
                        : ""
                    }


                    {
                        this.p_info.bestsubjects?
                        <div className="profile-info-row text-left pl-3">
                            <span className="profile-info-name">Best Subjects</span>
                            <span className="profile-info-value pl-1" >&nbsp;&nbsp;{this.p_info.bestsubjects}</span>                                         
                        </div>                           
                        : ""
                    }
 

                    {
                        this.p_info.educationplans?
                        <div className="profile-info-row  text-left info_row_gap pl-3">
                            <span className="profile-info-name">Further Education Plans</span>
                            <p className="profile-info-value pl-1" >{this.p_info.educationplans}</p>                                         
                        </div>                        
                        : ""
                    }
                                                                                                                                                               
                </div>
                <div className="col-xs-12 col-md-6">
                    <div className="profile-user-heading pl-3">Employment History</div>
                    {
                        this.p_info.occupation?
                        <div className="profile-info-row  text-left pl-3">
                            <span className="profile-info-name">Job 1</span>
                            <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.occupation}</span>
                        </div>                           
                        :""
                    }

                    {
                        this.p_info.jobdates1?
                        <div className="profile-info-row text-left pl-3">
                            <span className="profile-info-name">Job Term</span>
                            <span className="profile-info-value pl-1" >&nbsp;&nbsp;{this.p_info.jobdates1}</span>                                         
                        </div>                          
                        :""
                    }


                    {
                        this.p_info.jobduties1?
                        <div className="profile-info-row  text-left pl-3">
                            <span className="profile-info-name">Job Duties</span>
                            <span className="profile-info-value pl-1" >&nbsp;&nbsp;{this.p_info.jobduties1}</span>                                         
                        </div>                         
                        :""
                    }
 

                    {
                        this.p_info.jobposition2?
                        <div className="profile-info-row text-left info_row_gap pl-3">
                            <span className="profile-info-name">Job 2</span>
                            <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.jobposition2}</span>                                         
                        </div>                          
                        :""
                    }


                    {
                        this.p_info.jobdates2?
                        <div className="profile-info-row text-left pl-3">
                            <span className="profile-info-name">Job Term</span>
                            <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.jobdates2}</span>                                         
                        </div>                          
                        :""
                    }
  

                    {
                        this.p_info.jobduties2?
                        <div className="profile-info-row text-left pl-3">
                            <span className="profile-info-name">Job Duties</span>
                            <span className="profile-info-value pl-1" >&nbsp;&nbsp;{this.p_info.jobduties2}</span>                                         
                        </div>                         
                        :""
                    }
    

                    {
                        this.p_info.jobposition3?
                        <div className="profile-info-row text-left info_row_gap pl-3">
                            <span className="profile-info-name">Job 3</span>
                            <span className="profile-info-value pl-1" >&nbsp;&nbsp;{this.p_info.jobposition3}</span>                                         
                        </div>                          
                        :""
                    }

                    {
                        this.p_info.jobdates3?
                        <div className="profile-info-row text-left pl-3">
                            <span className="profile-info-name">Job Term</span>
                            <span className="profile-info-value pl-1" >&nbsp;&nbsp;{this.p_info.jobdates3}</span>                                         
                        </div>                        
                        :""
                    }    

                    {
                        this.p_info.jobduties3?
                        <div className="profile-info-row  text-left pl-3">
                            <span className="profile-info-name">Job Duties</span>
                            <span className="profile-info-value pl-1" >&nbsp;&nbsp;{this.p_info.jobduties3}</span>                                         
                        </div>                          
                        :""
                    }
                                                                                   
                </div>
            </div>
        </div>
        )
    }

    render_tab_health() {
        var len = this.p_info.familyBiologicalChart.length;
        var i=0;
        var items = [];

        for(i =0;i<len;i++){
            var member = this.p_info.familyBiologicalChart[i]; 
            var n_height = 'n/a';
            if (member.height) {
                n_height = member.height.replace(/\\/g,'');
            }

            if (member != undefined) {
                items.push(<tr>
                    <td className="text-left">{member.relation}</td>
                    <td className="text-left">{member.age}</td>
                    <td className="text-left">{member.haircolor}</td>
                    <td className="text-left">{member.eyecolor}</td>	               
                    <td className="text-left">{n_height}</td>	
                    <td className="text-left">{member.weight_}</td>	
                    <td className="text-left">{member.healthstatus}</td>	
                    <td className="text-left">{member.occupation}</td>	                                  
                </tr>);
            }                
        }

        return (
            <div id="tab-health">
                <div className="row mt-3">
                    <div className="col-xs-12 col-md-6">
                        <div className="profile-user-heading pl-3">Medical Information</div>
                        {
                            this.p_info.bloodtype?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Blood Type</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.bloodtype}</span>
                            </div>  
                            :""
                        }


                        {
                            this.p_info.birthcontrol?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Birth Control</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.birthcontrol}</span>
                            </div>        
                            :""                      
                        }                       

                        {
                            this.p_info.lastpapsmear?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Last Pap Smear</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.lastpapsmear}</span>
                            </div> 
                            :""
                        }

                        {
                            this.p_info.transfusion?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Transfusions</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.transfusion}</span>
                            </div> 
                            :""
                        }

                        {
                            this.p_info.twinstriplets?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Twins or Triplets in the Family</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.twinstriplets}</span>
                            </div> 
                            :""
                        }

                        {
                            this.p_info.hivaids?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">HIV/AIDs</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.hivaids}</span>
                            </div>
                            :""
                        } 

                        {
                            this.p_info.pregnum?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Number of Pregnancies</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.pregnum}</span>
                            </div>        
                            :""                     
                        }

                        {
                            this.p_info.pregcomplication?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Difficult Pregnancies</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.pregcomplication}</span>
                            </div>        
                            :""                     
                        }
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <div className="profile-user-heading pl-3">&nbsp;</div>
                        {
                            this.p_info.counseling?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Psychiatric Counseling</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.counseling}
                                {this.p_info.counselingexp?", ":""}
                                {this.p_info.counselingexp}
                                </span>
                            </div>   
                            :""                           
                        }

                        {
                            this.p_info.psychfacility?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Psychiatric Hospitalization</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.psychfacility}</span>
                            </div>
                            :""
                        }

                        {
                            this.p_info.medicalproblems?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Medical Problems</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.medicalproblems}</span>
                            </div> 
                            :""
                        } 

                        {
                            this.p_info.currentmeds?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Current Medications</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.currentmeds}</span>
                            </div>
                            :""
                        }

                        {
                            this.p_info.yearmeds?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Other Prescription Medications</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.yearmeds}</span>
                            </div>
                            :""
                        }

                        {
                            this.p_info.hospitalizations?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Hospitalization, Surgeries</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.hospitalizations}</span>
                            </div>
                            :""
                        }
                    </div>
                </div>
                {/* Medical History */}
                <div className="row info_row_gap">
                    <div className="col-xs-12 col-md-6">
                        <div className="profile-user-heading pl-3">Medical History</div>

                        {
                            this.p_info.handside
                            ?<div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Predominantly</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.handside}</span>
                            </div> 
                            :""
                        }

                        {
                            this.p_info.subabuse?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Abuse Program</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;
                                    {this.p_info.subabuse}
                                    {this.p_info.subabuseexp?",":""}
                                    {this.p_info.subabuseexp}
                                </span>
                            </div> 
                            :""
                        }

                        {
                            this.p_info.historymigraine?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Migraines</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.historymigraine}</span>
                            </div> 
                            :""
                        }

                        {
                            this.p_info.glasses?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Corrective Lenses/Glasses</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.glasses}</span>
                            </div> 
                            :""
                        }

                        {
                            this.p_info.downs?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Down's Syndrome</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.downs}</span>
                            </div>
                            :""
                        }

                        {
                            this.p_info.mental?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Mental Retardation</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.mental}</span>
                            </div>
                            :""
                        }

                        {
                            this.p_info.siezure?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Seizure disorder/Epilepsy</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.siezure}</span>
                            </div>
                            :""
                        }
   
                        {
                            this.p_info.muscular?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Muscular Dystrophy</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.muscular}</span>
                            </div>
                            :""
                        }

                        {
                            this.p_info.deafness?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Deafness of Hearing Problems</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.deafness}</span>
                            </div>
                            :""
                        }

                        {
                            this.p_info.blindness?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Blindness or Color Blindness</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.blindness}</span>
                            </div>
                            :""
                        }

                        {
                            this.p_info.glaucoma?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Glaucoma</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.glaucoma}</span>
                            </div>
                            :""
                        }

                        {
                            this.p_info.sighted?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Nearsighted or Farsighted</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.sighted}</span>
                            </div>
                            :""
                        }

                        {
                            this.p_info.depression?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Suffers From Depression</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.depression}</span>
                            </div>
                            :""
                        }

                        {
                            this.p_info.birthdefect?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Serious Birth Defect</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.birthdefect}</span>
                            </div>                            
                            :""
                        }
                    </div>


                    <div className="col-xs-12 col-md-6">
                        <div className="profile-user-heading pl-3">&nbsp;</div>
                        {
                            this.p_info.heartproblems?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Heart Problems</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.heartproblems}</span>
                            </div>                             
                            :""
                        }

                        {
                            this.p_info.STD?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">STD</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.STD}</span>
                            </div>                            
                            :""
                        }


                        {
                            this.p_info.miscarriage?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">More Than Two Miscarriages</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.miscarriage}</span>
                            </div>                            
                            :""
                        }

                        {
                            this.p_info.skindiscoloration?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Skin Discolorations/Spots/Rashes</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.skindiscoloration}</span>
                            </div>                            
                            :""
                        }

                        {
                            this.p_info.cancer?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Cancer</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.cancer}</span>
                            </div>                            
                            :""
                        } 

                        {
                            this.p_info.hemophilia?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Severe Bleeding Tendency (Hemophelioma)</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.hemophilia}</span>
                            </div>                            
                            :""
                        }

                        {
                            this.p_info.stroke?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Stroke</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.stroke}</span>
                            </div>                            
                            :""
                        } 

                        {
                            this.p_info.ulcer?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Ulcer</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.ulcer}</span>
                            </div>                            
                            :""
                        }

                        {
                            this.p_info.ovariancyst?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Ovarian Cysts</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.ovariancyst}</span>
                            </div>                            
                            :""
                        }  

                        {
                            this.p_info.uterinefibroid?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Uterine Fibroid</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.uterinefibroid}</span>
                            </div>                            
                            :""
                        }  
                        {
                            this.p_info.asthma?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Asthma</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.asthma}</span>
                            </div>                            
                            :""
                        } 

                        {
                            this.p_info.hyperactivity?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Hyperactivity</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.hyperactivity}</span>
                            </div>                            
                            :""
                        }                          

                        {
                            this.p_info.alcoholism?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Alcoholism</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.alcoholism}</span>
                            </div>                            
                            :""
                        }  
                        {
                            this.p_info.drugaddiction?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Drug Addiction</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.drugaddiction}</span>
                            </div>                            
                            :""
                        }  

                        {
                            this.p_info.learningdisability?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Learning Disability</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.learningdisability}</span>
                            </div>                            
                            :""
                        }                                                  

                        {
                            this.p_info.diabetes?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Diabetes Prior to Age 55</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.diabetes}</span>
                            </div>
                            :""
                        }

                        {
                            this.p_info.autism?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Autism</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.autism}</span>
                            </div>
                            :""
                        }
                    </div>              
                </div>
                {/* /Medical History */}

                <div className="row info_row_gap">
                    <div className="col-xs-12 col-md-6">
                        <div className="profile-user-heading pl-3">Family Ethnicity</div>
                        {
                            this.p_info.father1?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Father's Mother</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.father1}</span>
                            </div>  
                            :""
                        }

                        {
                            this.p_info.mother1?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Father's Father</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.father2}</span>
                            </div>  
                            :""
                        }
               
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <div className="profile-user-heading pl-3">&nbsp;</div>
                        {
                            this.p_info.father2?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Mother's Mother</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.mother1}</span>
                            </div> 
                            :""
                        }


                        {
                            this.p_info.mother2?
                            <div className="profile-info-row  text-left pl-3">
                                <span className="profile-info-name">Mother's Father</span>
                                <span className="profile-info-value pl-1">&nbsp;&nbsp;{this.p_info.mother2}</span>
                            </div>  
                            :""
                        }
                                               
                    </div>              
                </div>

                <div className="row info_row_gap">
                    <div className="col-xs-12 col-md-12">
                        <div className="profile-user-heading pl-3">Family Biological Chart</div>
                    </div>
                    <div className="col-xs-12 col-md-12 table-responsive mt-3">
                        
                        <table className="table table-bordered table-sm mt-1 table-relation">
                            <thead className="tbl-header">	
                                <tr className="">
                                    <th className="text-left">Relation</th>
                                    <th className="text-left">Age</th>
                                    <th className="text-left">Hair</th>
                                    <th className="text-left">Eyes</th>	               
                                    <th className="text-left">Height</th>	
                                    <th className="text-left">Weight</th>	
                                    <th className="text-left">Health Status</th>	
                                    <th className="text-left">Occupation</th>	
                                </tr>
                            </thead>
                            <tbody className="table_image">
                               {items}                                                                                                                                                              
                            </tbody>
                        </table>                        
                    </div>
              
                </div>                
            </div>
        )
    }

    render_tab_donation() {
        var len = 0;
        var i=0;
        var items = [];
        var freshCycles = this.p_info.freshCycles;
        var frozenCycles = this.p_info.frozenCycles;

        if (freshCycles==false && frozenCycles == false) {
            return (
                <div id="tab-donation">
                    <div className="profile-no-info">
                    All of our first time donors are excited about the possibility of helping you!
                    The donor you selected is one of our first time donors. If you are interested in learning more about her, please contact your matching specialist for more information.
                    </div>                                                                                                                           
                </div>
            );
        }


        return (
            <div id="tab-donation">
                {
                    freshCycles?
                <div className="row info_row_gap">
                    <div className="col-xs-12 col-md-6 ">
                        <div className="profile-info-row text-left pl-2 flex-row flex-align-center">
                            <img className="profile-info-icon1" width="25px" height="25px" src={svg_donor}></img> 
                            <span className="profile-user-heading1 pl-3">Previous Donations</span>
                        </div>
                    </div>

                    <div className="col-xs-12 col-md-12 table-responsive">
                        
                        <table className="table table-bordered table-sm mt-1 table-relation">
                            <thead className="tbl-header">	
                                <tr className="">
                                    <th className="text-left">Cycle Date</th>
                                    <th className="text-left">Eggs</th>
                                    <th className="text-left">Embryos</th>
                                    <th className="text-left">Result</th>
                                </tr>
                            </thead>
                            <tbody className="table_image">
                                {freshCycles.map(function(obj, i){
                                    return (
                                        <tr>
                                            <td>{obj.cycledate}</td>
                                            <td>{obj.eggs}</td>
                                            <td>{obj.embryos}</td>
                                            <td>{obj.pregnancyresult}</td>
                                        </tr>
                                    );

                                })}
                            </tbody>
                        </table>                        
                    </div>              
                </div>  

                :""
                }
                {
                    frozenCycles?
                
                <div className="row info_row_gap">
                    <div className="col-xs-12 col-md-6 ">
                        <div className="profile-info-row text-left pl-2 flex-row flex-align-center">
                            <img className="profile-info-icon1" width="25px" height="25px" src={svg_frozen}></img> 
                            <span className="profile-user-heading1 pl-3">Frozen Egg Donations</span>
                        </div>
                    </div>

                    <div className="col-xs-12 col-md-12 table-responsive">
                        
                        <table className="table table-bordered table-sm mt-1 table-relation">
                            <thead className="tbl-header">	
                                <tr className="">
                                    <th className="text-left">Cycle Date</th>
                                    <th className="text-left">Eggs</th>
                                    <th className="text-left">Frozen</th>
                                    <th className="text-left">Available</th>
                                </tr>
                            </thead>
                            <tbody className="table_image">
                                {frozenCycles.map(function(obj, i){
                                    return (
                                        <tr>
                                            <td>{obj.thaw_date}</td>
                                            <td>{obj.no_eggs_thawed}</td>
                                            <td>{obj.frozen}</td>
                                            <td>{obj.pregnancy_result}</td>
                                        </tr>
                                    );

                                })}
                            </tbody>
                        </table>                        
                    </div>              
                </div>                        
            
                :""}
            </div>
        )
    }

    render_loading() {

        return (
            <div>
                <header></header>
                <div id="loading"></div>                  
            </div>
        );
    }

    render_error(){
        
        return (
            <div>
                <Header></Header>
                <div className="content">
                    <div className="container">    
                        <div className="row mt-5" >
                            <div className="col-md-12">
                                <div className="profile-user-heading text-center">{this.api_message}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    
    render_profile_header() {
        const {bookmark, reserves, reserved} = this.state;

        console.log('Profile Reserves:' + reserves + ', Reserved:' + reserved);
        var city = this.p_info.city.trim();
        var state = this.p_info.state.trim();
        var items = [];

        
        if (isMobile) {
            if (this.p_info.donatedbefore == 'yes') {
                items.push(<img className="profile-info-icon" src={svg_donor} key="1"></img>);
                items.push(<div className="profile-heading-value small black pl-1" key="2"> <span>Previous Donor</span> </div> );            
            }
    
            if (this.p_info.show_home > 0) {
                items.push(<img className="profile-info-icon ml-3" src={svg_cycle} key="3" id="cylce_img"></img> );
                items.push(<div className="profile-heading-value small black pl-1" key="4"> <span>Shared Cycles</span> </div>);
            }
    
            if (this.p_info.freezeeggs >0 ) {
                items.push(<img className="profile-info-icon pl-3" src={svg_frozen} key="5"></img> );
                items.push(<div className="profile-heading-value small black pl-1" key="6"> <span>Frozen Eggs</span> </div>);
            } 

            return (
                <div className="profile-pane">
                    {
                        F_UTIL.is_recipient()?
                        <a className="icon-fav" onClick={this.onClickBookmark.bind(this)}>
                            {
                            bookmark
                                ?<i className="fa fa-heart" aria-hidden="true"></i>
                                :<i className="fa fa-heart-o" aria-hidden="true"></i>
                            }
                        </a>:""
                    }

                    <div className="row pl-3">
                        <div className="profile-heading-row flex-row flex-align-center text-left pb-3">
                            <h1 className="profile-info-heading">{this.p_info.firstname}  (ID {this.p_info.id})</h1>
                            <span className="profile-info-status">·&nbsp;{this.p_info.cyclestatus}</span>
                        </div>     
                        {
                            city && state ?
                            <div className="profile-user-subheading">{city},&nbsp;{state}&nbsp;&nbsp;·&nbsp;&nbsp; {this.p_info.age} years old</div>:                                                        
                            <div className="profile-user-subheading">{this.p_info.age} years old</div>
                        }                   
                        
                    </div>

                    <div className="row">
                        {this.render_interested_link()}
                    </div>

                    <div className="row profile-header-row mt-3">
                        <LazyLoadImage 
                            key={this.p_id} 
                            wrapperClassName="profile-img-box" 
                            className="profile-img" 
                            effect="black-and-white" 
                            alt="Profile Photo" 
                            placeholderSrc={img_placeholder} 
                            onContextMenu={this.onImgContextMenu.bind(this)} 
                            src={this.p_info.default_photo} />

                        <div className="info-box">
                            {
                                this.p_info.height?
                                <div className="profile-header-row d-flex flex-row align-items-center justify-content-start">
                                    <img className="profile-info-icon" src={svg_height}></img> 
                                    <div className="profile-heading-value pl-3"> <span>{this.p_info.height}</span> </div>
                                </div>
                                :""
                            }

                            <div className="profile-header-row d-flex flex-row align-items-center justify-content-start">
                                <img className="profile-info-icon" src={svg_weight}></img> 
                                <div className="profile-heading-value pl-3"> 
                                    <span>
                                        {this.p_info.weight}&nbsp;lbs
                                        {this.p_info.bodytype?"  /  ":""}
                                        {this.p_info.bodytype}
                                    </span>
                                </div>                                        
                            </div>

                            {
                                this.p_info.eyecolor?
                                <div className="profile-header-row d-flex flex-row align-items-center justify-content-start">
                                    <img className="profile-info-icon" src={svg_eye}></img>
                                    <div className="profile-heading-value pl-3"> <span>{this.p_info.eyecolor}</span> </div>                                        
                                </div>
                                :""
                            }

                            {
                                this.p_info.haircolor && this.p_info.hairtype ?
                                <div className="profile-header-row d-flex flex-row align-items-center justify-content-start">
                                    <img className="profile-info-icon" src={svg_hair}></img> 
                                    <div className="profile-heading-value pl-3"> 
                                        <span>
                                        <span>
                                            {this.p_info.haircolor}
                                            {this.p_info.hairtype?"  /  ":""}
                                            {this.p_info.hairtype}
                                        </span>                                    
                                        </span> 
                                    </div>                                        
                                </div>
                                :""
                            }

                            {
                                this.p_info.race?
                                <div className="profile-header-row d-flex flex-row align-items-center justify-content-start">
                                    <img className="profile-info-icon" src={svg_lang}></img>
                                    <div className="profile-heading-value pl-3"> <span>{this.p_info.race}</span> </div>                                        
                                </div> 
                                :""
                            }
                         
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-5">
                            {
                                this.p_info.complexion?
                                <div className="profile-header-row text-left">
                                    <div className="profile-heading-name"> Complexion </div>
                                    <div className="profile-heading-value"> <span>{this.p_info.complexion}</span> </div> 
                                </div> 
                                :""
                            }
                       
                        </div>
                        <div className="col-7">
                            {
                                this.p_info.otherfeatures?
                                <div className="profile-header-row text-left">
                                    <div className="profile-heading-name"> Features </div>
                                    <div className="profile-heading-value"> <span>{this.p_info.otherfeatures}</span> </div>                                         
                                </div>
                                :""
                            }                        
                        </div>
                    </div>

                    <div className="row mt-3 pl-3">
                        {items}
                    </div>

                    <div className="row">
                        {this.render_reserve_button()}
                    </div>
                </div>
            )
        }


        if (this.p_info.donatedbefore == 'yes') {
            items.push(<img className="profile-info-icon" src={svg_donor} key="1"></img>);
            items.push(<div className="profile-heading-value black pl-3" key="2"> <span>Previous Donor</span> </div> );            
        }

        if (this.p_info.show_home > 0) {
            items.push(<img className="profile-info-icon ml-5" src={svg_cycle} key="3" id="cylce_img"></img> );
            items.push(<div className="profile-heading-value black pl-3" key="4"> <span>Shared Cycles</span> </div>);
        }

        if (this.p_info.freezeeggs >0 ) {
            items.push(<img className="profile-info-icon pl-5" src={svg_frozen} key="5"></img> );
            items.push(<div className="profile-heading-value black pl-3" key="6"> <span>Frozen Eggs</span> </div>);
        } 
        return (
            <div className="profile-pane">
                {
                    F_UTIL.is_recipient()?
                    <a className="icon-fav" onClick={this.onClickBookmark.bind(this)}>
                        {
                            bookmark
                                ?<i className="fa fa-heart" aria-hidden="true"></i>
                                :<i className="fa fa-heart-o" aria-hidden="true"></i>
                        }
                    </a> : ""                
                }               


                <LazyLoadImage 
                            key={this.p_id} 
                            wrapperClassName="profile-img-box" 
                            className="profile-img" 
                            effect="black-and-white" 
                            alt="Profile Photo" 
                            placeholderSrc={img_placeholder} 
                            onContextMenu={this.onImgContextMenu.bind(this)} 
                            src={this.p_info.default_photo} />

                <div className="info-box">
                    <div className="profile-heading-row flex-row flex-align-center text-left pb-3">
                        <h1 className="profile-info-heading">{this.p_info.firstname}  (ID {this.p_info.id})</h1>
                        <span className="profile-info-status">·&nbsp;{this.p_info.cyclestatus}</span>
                    </div>
                    {
                        city && state ?
                        <div className="profile-user-subheading">{city},&nbsp;{state}&nbsp;&nbsp;·&nbsp;&nbsp; {this.p_info.age} years old&nbsp;({this.p_info.dob})</div>:
                        <div className="profile-user-subheading">{this.p_info.age} years old&nbsp;({this.p_info.dob})</div>
                    }
                    
                    {this.render_interested_link()}

                    <div className="profile-user-info">
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                {
                                    this.p_info.height?
                                    <div className="profile-header-row flex-row flex-align-center text-left">
                                        <img className="profile-info-icon" src={svg_height}></img> 
                                        <div className="profile-heading-value pl-3"> <span>{this.p_info.height}</span> </div>
                                    </div>
                                    :""
                                }

                                {
                                    this.p_info.weight && this.p_info.bodytype?
                                    <div className="profile-header-row flex-row flex-align-center text-left">
                                        <img className="profile-info-icon" src={svg_weight}></img> 
                                        <div className="profile-heading-value pl-3"> 
                                            <span>
                                                {this.p_info.weight}&nbsp;lbs
                                                {this.p_info.bodytype?"  /  ":""}
                                                {this.p_info.bodytype}
                                            </span> 
                                        </div>                                        
                                    </div>
                                    :""
                                }

                                {
                                    this.p_info.eyecolor?
                                    <div className="profile-header-row flex-row flex-align-center text-left">
                                        <img className="profile-info-icon" src={svg_eye}></img>
                                        <div className="profile-heading-value pl-3"> <span>{this.p_info.eyecolor}</span> </div>                                        
                                    </div>
                                    :""
                                }

                                {
                                    this.p_info.haircolor || this.p_info.hairtype?
                                    <div className="profile-header-row flex-row flex-align-center text-left">
                                        <img className="profile-info-icon" src={svg_hair}></img> 
                                        <div className="profile-heading-value pl-3"> 
                                            <span>                                                    
                                                {this.p_info.haircolor}
                                                {this.p_info.hairtype?"  /  ":""}
                                                {this.p_info.hairtype}                                                    
                                            </span> 
                                        </div>                                        
                                    </div> :""                              
                                }


                                {
                                    this.p_info.race?
                                    <div className="profile-header-row flex-row flex-align-center text-left">
                                        <img className="profile-info-icon" src={svg_lang}></img>
                                        <div className="profile-heading-value pl-3"> <span>{this.p_info.race}</span> </div>                                        
                                    </div>  
                                    :""
                                }                                                                                                                                                              
                            </div>
                            <div className="col-xs-12 col-md-6">
                                {
                                    this.p_info.complexion?
                                    <div className="profile-header-row text-left">
                                        <div className="profile-heading-name"> Complexion </div>
                                        <div className="profile-heading-value"> <span>{this.p_info.complexion}</span> </div> 
                                    </div>
                                    :""
                                }


                                {
                                    this.p_info.otherfeatures?
                                    <div className="profile-header-row text-left">
                                        <div className="profile-heading-name"> Features </div>
                                        <div className="profile-heading-value"> <span>{this.p_info.otherfeatures}</span> </div>                                         
                                    </div>
                                    :""
                                }

                            </div>
                        </div>

                        <div className="profile-header-row flex-row flex-align-center text-left mt-5">
                        {items}
                        </div>
                    </div>

                    {this.render_reserve_button()}
                </div>
            </div>            
        )

    }
	

    render_interested_link() {
         
        return (
            <div className="profile-interested-box">
                <a href="#" className="help-link" onClick={this.onClickInterested.bind(this)}>
                    <i className="fa fa-play" aria-hidden="true"></i>
                    <span>Interested?</span>   
                </a>
            </div>
        );
    }

    render_reserve_button() {
        const {reserves, reserved} = this.state;
        if ( F_UTIL.is_recipient() && this.p_info.freezeeggs > 0) {
            if (reserves <3 && reserved == false) {
                return (
                    <div className="profile-info-row flex-row flex-align-center text-left mt-4">
                        <button className="btn btn-white" onClick={this.onClickReserve.bind(this)}>
                            RESERVE FROZEN EGGS
                        </button>
                        <img className="profile-info-icon pl-3" src={svg_info} data-tip data-for='global'></img> 
                        <ReactTooltip id='global' aria-haspopup='true' place="bottom" type="info" effect="solid">
                            <p>By reserving a frozen egg you will be placed on a waiting list for this donor's frozen eggs.</p>
                            <p>Our staff will contact you with info on available inventory.</p>

                        </ReactTooltip>
                        
                    </div>                    
                );
            } else {
                return (
                    <div className="profile-info-row flex-row flex-align-center text-left mt-4">
                        <button className="btn btn-white" onClick={this.onClickUnreserve.bind(this)}>
                            UNRESERVE FROZEN EGGS
                        </button>
                        <img className="profile-info-icon pl-3" src={svg_info}  data-tip data-for='global'></img> 
                        <ReactTooltip id='global' aria-haspopup='true' place="bottom" type="info" effect="solid">
                            <p>By reserving a frozen egg you will be placed on a waiting list for this donor's frozen eggs.</p>
                            <p>Our staff will contact you with info on available inventory.</p>

                        </ReactTooltip>
                    </div>                    
                );                
            }

        }
    }

    render() {
        const {load_data} = this.state;

        if (load_data < 0) {
            return this.render_error();
        }
            
        if (load_data == 0) {
            return this.render_loading();
        }
            
        return (
            <div>
                <Header></Header>
                <ReactNotification ref={this.notificationDOMRef} />
                <div className="profile-content">
                    <div className="container">
                        {this.render_profile_header()}
                    </div>
                </div>
                
                <div className="profile-tab">
                    {this.render_tab_nav()}
                    <div className="profile-tab-content pt-5">
                        <div className="container">
                            {this.render_tab()}
                        </div>
                    </div>
                </div>
                {this.renderModalBookmark()}   
                {this.renderModalReserve()}   
                {this.renderModalUnreserve()}   
                {this.renderModalInterested()}
            </div>
        );
  }
}

export default TProfile;
