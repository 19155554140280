import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import  { Redirect } from 'react-router-dom'
import $ from 'jquery';
import queryString from 'query-string';
import {isMobile} from 'react-device-detect';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';

import '../App.css';

import img_logo from '../assets/images/logo.png';

class Header extends Component {

    constructor(props) {
        super (props);
        this.state = {
        }

    }

    componentWillMount() {

    }

 
    componentDidMount() {
        
    }

    render() {
        const {loggined} = this.state;
        var type = global.type;
        
        return (

            <Navbar color="light" light expand="md">
                <div className="container">
                    <NavbarBrand href="/"><img className="logo-img" src={img_logo} alt=""></img></NavbarBrand>
                </div>
            </Navbar>
           
        );
    }
}

export default Header;
